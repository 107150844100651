import React from "react";

const subTaskArrow = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6997 29.1084C22.5075 29.109 22.3177 29.0666 22.1441 28.9843C21.9706 28.902 21.8176 28.7819 21.6966 28.6327L15.2925 20.8107C15.1034 20.5807 15 20.2921 15 19.9943C15 19.6966 15.1034 19.408 15.2925 19.178L21.7223 11.4644C21.9405 11.2019 22.2542 11.0368 22.5942 11.0054C22.9343 10.9741 23.2728 11.0791 23.5354 11.2973C23.7981 11.5155 23.9632 11.8291 23.9946 12.169C24.0259 12.509 23.9209 12.8474 23.7026 13.11L17.9544 20.0008L23.7027 27C23.8599 27.1887 23.9598 27.4185 23.9905 27.6622C24.0212 27.9059 23.9815 28.1532 23.876 28.375C23.7704 28.5968 23.6036 28.7838 23.3951 28.9137C23.1867 29.0437 22.9453 29.1112 22.6997 29.1084Z"
      fill="#424242"
    />
  </svg>
);

export default subTaskArrow;
