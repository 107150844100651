export const words = [
    'Лето',
    'Бремя',
    'Богатство',
    'Жизнь',
    'Холод',
    'Терпеливость',
    'Свобода',
    'Ужин',
    'Надежность',
    'Вальс',
    'Энергия',
    'Скука',
    'Справедливость',
    'Движение',
    'Досада',
    'Надежда',
    'Виновность',
    'Счастье',
];