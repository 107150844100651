export const result = {
	task_id: undefined,
	start_date: undefined,
	end_date: undefined,
	data: [],
};

export const initialState = {
	name: undefined,
	email: undefined,
	test_id: undefined,
	// results: [],
};
